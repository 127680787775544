<template>
  <div class="supplier">
    <img
      class="banner"
      src="https://oss.pinmallzj.com/image/maintain/2023/04/11/img_supplier_banner_1-ssprayil5r39.png"
      alt=""
    />

    <div class="process">
      <p class="title">供应商入驻流程</p>
      <img src="@/assets/imgs/img_supplier_process.png" alt="" />
    </div>

    <div class="prder-process">
      <p class="title">商品订单流程</p>
      <div class="process-box">
        <div class="process-item">
          <img src="@/assets/imgs/icon_supplier_sjsp@2x.png" alt="" />
          <p>上架商品</p>
        </div>
        <div class="process-item">
          <img src="@/assets/imgs/icon_supplier_ddfh@2x.png" alt="" />
          <p>订单发货</p>
        </div>
        <div class="process-item">
          <img src="@/assets/imgs/icon_supplier_dz@2x.png" alt="" />
          <p>对账</p>
        </div>
        <div class="process-item">
          <img src="@/assets/imgs/icon_supplier_kjfp@2x.png" alt="" />
          <p>开具发票</p>
        </div>
        <div class="process-item">
          <img src="@/assets/imgs/icon_supplier_zdfz@2x.png" alt="" />
          <p>自动分账</p>
        </div>
      </div>
    </div>

    <div class="brand">
      <div class="title">2000+ 知名品牌合作</div>
      <div class="text">知名品牌一级代理 无中间商赚差价</div>
      <div class="brand-box">
        <div class="brand-item" v-for="(item, index) in brandList" :key="index">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>

    <div class="bigUI">
      <div class="title">全品类商品，10万家供应商首选平台</div>
      <div class="ui-box">
        <div class="ui-item">
          <p class="num">10<span>年</span></p>
          <p class="text">电商服务经验</p>
        </div>
        <div class="ui-item">
          <p class="num">10<span>万+</span></p>
          <p class="text">大型企业客户</p>
        </div>
        <div class="ui-item">
          <p class="num">30<span>千+</span></p>
          <p class="text">全品类供应商</p>
        </div>
        <div class="ui-item">
          <p class="num">10<span>年</span></p>
          <p class="text">供应商合作经验</p>
        </div>
      </div>
    </div>

    <div class="application">
      <div class="appli-box">
        <div class="left-box">
          <p class="name">联系客服</p>
          <p class="call">拨打咨询电话，立即入驻聚如平台</p>
          <img src="@/assets/imgs/img_supplier_kfdh@2x.png" alt="" />
          <p class="phone">0571 - 89973966</p>
          <p class="time">工作时间：9:00-18:00</p>
        </div>
        <div class="right-box">
          <p class="name">供应商入驻申请</p>
          <input type="text" placeholder="请输入供应商名称" v-model="applyData.name" />
		  <input type="text" placeholder="请输入手机号" v-model="applyData.phone" />
		  <div class="_box">
			  <input type="text" placeholder="请输入验证码" v-model="applyData.captcha" />
			  <div class="send" @click="getCode">{{ text }}</div>
		  </div>
		  <el-select class="item-select" v-model="applyData.type" clearable placeholder="请输入供应商类型（选填）">
		      <el-option
		  		v-for="item in categoryOpts"
		  		:key="item.id"
		  		:label="item.name"
		  		:value="item.id">
		      </el-option>
		  </el-select>
		  <el-button type="primary" class="btn" @click="confirm" :loading="!isConfirm">预约入驻</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sendVerifyCode from "@/mixins/SendVerifyCode";
import { entSettled, getCodeApi, categoryList, supplierSettled } from "@/api/user.js";
const reg = /^1[3456789]{1}\d{9}$/;
export default {
	mixins: [sendVerifyCode],
  name: "supplier",
  data() {
    return {
      brandList: [
        {
          url: require("@/assets/imgs/img_supplier_sms@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_opzm@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_dl@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_jy@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_wjg@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_kws@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_hx@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_fk@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_hxf@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_jx@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_mw@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_jly@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_sbr@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_xiaomi@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_meidi@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_bear@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_slr@2x.png"),
        },
        {
          url: require("@/assets/imgs/img_supplier_skg@2x.png"),
        },
      ],
	  applyData:{
		  name: '',
		  phone: '',
		  captcha: '',
		  type: '',
	  },
	  categoryOpts:[],
	  isConfirm:true,
    };
  },
  created() {
		this.getCategoryList();
  },
  methods:{
		getCategoryList() {
			categoryList().then(res => {
				// console.log('categoryList',res)
				if (res.code == 200) {
					this.categoryOpts = res.data;
				}
			}).catch(error => {
				console.log(error)
			})
		},
		//发送验证码
		getCode() {
			let that = this;
	  
			if (this.applyData.phone == "" || !reg.test(this.applyData.phone)) {
				return this.$message({
					message: "手机号码不正确，请重新输入!",
					type: "error",
				});
			}
	  
			const data = that.$qs.stringify({ phone: that.applyData.phone - "" });
			if (!this.disabled) {
				this.disabled = true;
				getCodeApi(data)
					.then((res) => {
						if (res.code === 200) {
							this.$message({
								message: "发送成功",
								type: "success",
							});
							that.sendCode();
						} else if (res.code === 500) {
							this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$message.error("发送失败");
				});
			}
		},
		confirm() {
			if (!this.applyData.name) {
				return this.$message({
					message: "供应商名称不能为空!",
					type: "error",
				});
			}
			if (!this.applyData.phone) {
				return this.$message({
					message: "手机号码不能为空!",
					type: "error",
				});
			}
			if (!reg.test(this.applyData.phone)) {
				return this.$message({
					message: "手机号码不正确，请重新输入!",
					type: "error",
				});
			}
			if (!this.applyData.captcha) {
				return this.$message({
					message: "验证码不能为空!",
					type: "error",
				});
			}
			if (!this.isConfirm) return false;
			this.isConfirm = false;
			supplierSettled(this.applyData).then((res) => {
				// console.log('res',res)
				this.isConfirm = true;
			    if (res.code !== 200) {
					this.$message({
						message: res.message,
						type: "error",
					});
			    } else {
					this.$message({
						message: '申请成功，请等待平台审核!',
						type: "success",
						duration: 5000
					});
			    }
			}).catch((error) => {
			    console.log(error);
			});
		}
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
}

.process {
  height: 400px;
  text-align: center;
  padding-bottom: 80px;

  .title {
    margin-top: 80px;
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #000000;
  }

  img {
    margin: auto;
    margin-top: 40px;
    width: 975px;
    height: 261px;
  }
}

.prder-process {
  padding-top: 80px;
  padding-bottom: 80px;
  height: 405px;
  background-image: url("https://oss.pinmallzj.com/crmebimage/maintain/2022/07/14/f274910a90e4465a8ac2b1e03e2a2c53nnfbjc3azb.png");
  background-size: contain;

  .title {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #fff;
  }
}

.process-box {
  margin: 40px auto;
  width: 1011px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);

  div:last-child {
    border-right: none;
  }

  .process-item {
    padding-top: 48px;
    width: 202px;
    height: 208px;
    background: #ffffff;
    text-align: center;
    border-right: 1px solid #ddd;
    img {
      margin: auto;
      width: 64px;
      height: 64px;
    }

    p {
      padding-top: 26px;
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
}

.brand {
  padding-top: 80px;
  padding-bottom: 76px;
  .title {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #000000;
  }

  .text {
    text-align: center;
    font-size: 16px;
    color: #000000;
    margin-top: 10px;
  }
}

.brand-box {
  margin: 40px auto;
  width: 1236px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  div:nth-child(n + 7) {
    margin-top: 28px;
  }

  .brand-item {
    width: 170px;
    height: 120px;
    margin-right: 36px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bigUI {
  height: 401px;
  background-image: url("https://oss.pinmallzj.com/crmebimage/maintain/2022/07/14/684a524659b04995bf1d37bf6c16c60f8xfw1kdcfz.png");
  background-size: contain;
  padding-top: 64px;
  .title {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: #fff;
  }
}

.ui-box {
  margin-top: 46px;
  display: flex;
  justify-content: center;
  div:last-child {
    margin-right: 0;
  }

  .ui-item {
    border-radius: 50%;
    border: 2px solid #fff;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 93px;

    .num {
      font-size: 51px;
      color: #fcf062;

      span {
        font-size: 19px;
        color: #ffffff;
      }
    }

    .text {
      margin-top: 18px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.application {
  padding: 80px 0;

  .appli-box {
    width: 1266px;
    height: 426px;
    margin: auto;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;

    .left-box {
      margin-top: 54px;
      width: 49.5%;
      height: 255px;
      border-right: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      align-items: center;
      .name {
        font-size: 19px;
        font-weight: 700;
        color: #333333;
      }
      .call {
        font-size: 13px;
        color: #333333;
        margin-top: 18px;
      }
      .phone {
        margin-top: 32px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
      }
      .time {
        margin-top: 10px;
        font-size: 13px;
        color: #333333;
      }

      img {
        margin-top: 32px;
        width: 100px;
        height: 100px;
      }
    }
  }
}

.right-box {
  margin-top: 42px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .name {
    font-size: 19px;
    font-weight: 700;
    color: #333333;
  }

  input:nth-child(2) {
    margin-top: 30px !important;
  }

  input {
    margin-top: 15px;
    border: none;
    outline: none;
    width: 370px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 10px 16px;
    font-size: 10px;
	background-color: #ffffff;
	color: #606266;
  }

  

  .btn {
    margin-top: 37px;
    width: 223px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    background: #2b7efb;
    font-size: 13px;
    color: #ffffff;
    cursor: pointer;
	padding:0;
	border-radius: 0;
  }
  
  ._box{
	  width: 370px;
	  height: 40px;
	  display: flex;
	  justify-content: space-between;
	  
	  input {
	    margin-top: 15px;
	    border: none;
	    outline: none;
	    width: 276px;
	    height: 40px;
	    border: 1px solid #ddd;
	    padding: 10px 16px;
	    font-size: 10px;
		background-color: #ffffff;
		color: #606266;
	  }
	  
	  .send {
	    cursor: pointer;
		margin-top: 15px;
	    width: 80px;
	    height: 40px;
	    border: 1px solid #125afd;
	    text-align: center;
	    line-height: 40px;
	    font-size: 13px;
	    color: #107dff;
	  }
  }
  
  .item-select{
	  margin-top: 30px;
	  width: 370px;
	  height: 40px;
	  font-size: 10px;
	  background-color: #ffffff;
	  
	  ::v-deep .el-input__inner{
		  border-radius: 0;
	  }
  }
  
  input::-webkit-input-placeholder {
    color: #999999;
  }
  input::-webkit-input-placeholder {
    color: #999999;
  }
  input:-ms-input-placeholder {
    color: #999999;
  }
  
  input:focus {
    outline: 1px solid #42a7ff;
  }
}
</style>
